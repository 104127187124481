<template>
  <router-view></router-view>
</template>

<script>
import store from "@/store/index";

export default {
  name: "Warehouse",
  created() {
    this.redirect('DashboardInformation');
  },
  computed: {
    isLoadedData () {
      const { id, name } = store.state.userInfo;
      return !!(id && name);
    }
  },
  watch: {
    isLoadedData (value) {
      value && this.redirect('DashboardInformation');
    }
  },
  methods: {
    redirect (to) {
      const { id, name, hasCompany, createdAt } = store.state.userInfo;
      if ((id && name) && (!hasCompany || !createdAt)) {
        this.$router.push({ name: to });
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
